import { createStore } from 'vuex'
import WeChat from './wechat'
export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        other: WeChat
    }
})