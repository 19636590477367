<template>
  <NavBar/>
  <router-view/>
  <put-record/>

  
</template>

<script>

import NavBar from './components/NavBar.vue';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import PutRecord from './components/PutRecord.vue';

export default {
  components:{
    NavBar,
    PutRecord,
  },
  setup() {
    
  },
}
</script>

<style>
 
</style>
