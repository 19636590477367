<template>
    <div class="container content-field">
        <div class="card text-center mb-3 mx-auto content center-container" style="width: 100%;">
            <div class="text-center">
                <p data-v-1159ca10="" style="display: flex; align-items: center; margin-bottom: 0px;">
                    <a class="bottom-link" href="https://beian.miit.gov.cn/" target="_blank" data-v-1159ca10="" style="color: gray; font-size: 13px;">
                        赣ICP备2023012782号
                    </a>
                    <span data-v-1159ca10="" style="font-size: 0.7rem;">&amp;</span>
                    <a class="bottom-link" target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch?code=36073302000197" data-v-1159ca10="" style="color: gray; font-size: 13px;">
                        <img class="record" src="https://www.shuishuia.cn/static/img/record/record.png" style="float: left; margin: 0px auto;" height="15px">
                        赣公网安备36073302000197号
                    </a>
                </p>
            </div>
        </div>
    </div>

</template>
<script>
export default {
    name:"PutRecord",
    setup() {
        
    },
}
</script>
<style scoped>
a{
    text-decoration:none
}
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px; 
}
</style>
