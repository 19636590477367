<template>
    <ContentFieldVue>
        <div class="userName">{{ user.alt }}</div>
        <br>
        <div class="userName">
            <img :src="user.photo" alt="">
        </div>
    </ContentFieldVue>
</template>
<script>
import ContentFieldVue from '@/components/ContentField.vue'
import { useRoute } from 'vue-router';
// import store from '@/store';
import { useStore } from 'vuex';
// import { reactive } from 'vue';

export default {
    name:"SelfSpaceView",
    components:{
        ContentFieldVue,
    },
    setup() {
        const route = useRoute();
        const userId = parseInt(route.params.userId)-1;
        const store = useStore();
        const user = store.state.other.user[userId];
        
      
        return {
            user
        }
    },
}
</script>
<style scoped>
.userName{
    text-align: center;
}

img{
    height:50vh;
}
</style>