<template>
    <ContentField>
        404
    </ContentField>
</template>
<script>
import ContentField from '@/components/ContentField.vue';
export default {
    name:"NotFound",
    components:{
        ContentField,
    },
    setup() {
        
    },
}
</script>
<style scoped>

</style>