import { createRouter, createWebHistory } from 'vue-router'
import AboutView from '../views/about/AboutView';
import OtherView from '../views/other/OtherView';
import NotFound from '../views/error/NotFound';
import HomeView from '../views/home/HomeView';
import NoteView from '../views/note/NoteView';
import ToolsView from '../views/tools/ToolsView';
import SelfSpaceview from '@/views/other/selfspace/SelfSpaceview'
const routes = [{
        path: "/",
        redirect: "/home/"
    },
    {
        path: "/home/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/note/",
        name: "note",
        component: NoteView,
    },
    {
        path: "/tools/",
        name: "tools",
        component: ToolsView,
    },
    {
        path: "/other/",
        name: "other",
        component: OtherView,
    },
    {
        path: '/other/selfspace/:userId/',
        name: "selfSpaceView",
        component: SelfSpaceview,
    },
    {
        path: "/about/",
        name: "about",
        component: AboutView,
    },
    {
        path: "/404",
        name: "404",
        component: NotFound,
    },
    {
        path: "/:catchAll(.*)",
        redirect: "/404/"
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router