<template>
    <ContentField>
        笔记
    </ContentField>
</template>
<script>
import ContentField from '@/components/ContentField.vue';
export default {
    name:"NoteView",
    components:{
        ContentField,
    },
    setup() {
        
    },
}
</script>
<style scoped>

</style>