<template>
    <ContentField>
        tools
    </ContentField>
</template>
<script>
import ContentField from '@/components/ContentField.vue';
export default {
    name:"ToolsView",
    components:{
        ContentField,
    },
    setup() {
        
    },
}
</script>
<style scoped>

</style>


 