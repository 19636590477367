<template>
    <ContentField>
        <div v-for="user in list" :key="user.id" @click="open_user_SelfSpace(user.id)">
            <ContentField>
                {{ user.alt }}
            </ContentField>
        </div>
    </ContentField>
</template>
<script>
import ContentField from '@/components/ContentField.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';
// import { reactive } from 'vue';
import router from '@/router';
export default {
    name:"OtherView",
    components:{
        ContentField,
    },
    setup() {
        const Store = useStore();
        const list = computed(()=> {return Store.state.other.user});

        const open_user_SelfSpace = userId =>{
            router.push({
                name:"selfSpaceView",
                params:{
                    userId
                }
            })
        }

        return {
            list,
            open_user_SelfSpace,
        }
        
    },
}
</script>
<style scoped>
div{
    cursor:pointer;
}
</style>