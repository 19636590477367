const WeChat = {
    state: {
        user: [{
                id: "1",
                alt: "Lauv",
                photo: "https://www.shuishuia.cn/static/img/wechat/ger.jpg",
                username: "Lauv",
            },
            {
                id: "2",
                alt: "BLUE",
                photo: "https://www.shuishuia.cn/static/img/wechat/blue.jpg",
                username: "BLUE",
            },
            {
                id: "3",
                alt: "脐橙",
                photo: "",
                username: "",
            },
            {
                id: "4",
                alt: "author",
                photo: "https://www.shuishuia.cn/static/img/wechat/author.jpg",
                username: "爱捣蛋的童鞋",
            }
        ]
    },
    getters: { //获取经过处理后的state里的数据
    },
    mutations: { //vue限制mutations不能进行异步操作
    },
    actions: { //定义所有对state的更新方式
    },
    modules: { //将state进行分割
    }
};
export default WeChat;