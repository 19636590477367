<template>
  <ContentField>
    <div>
      <h2>欢迎来到shushuia</h2>
      <br>
      hh
    </div>  
  </ContentField>
</template>
<script>
import ContentField from '@/components/ContentField.vue';
export default {
  name:"HomeView",
  components:{
      ContentField,
  },
  setup() {
      
  },
}
</script>
<style scoped>
div {
    text-align: center;
}
</style>