<template>
<nav class="navbar navbar-expand-lg bg-body-tertiary">
  <div class="container">
    <router-link class="navbar-brand" :to="{name:'home'}">水水啊</router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link :class="route_name== 'home' ? 'nav-link active' : 'nav-link'" :to="{name:'home'}">首页</router-link>
        </li>
        <li class="nav-item">
          <router-link :class="route_name== 'note' ? 'nav-link active' : 'nav-link'" :to="{name:'note'}">笔记</router-link>
        </li>
        <li class="nav-item">
          <router-link :class="route_name== 'tools' ? 'nav-link active' : 'nav-link'" :to="{name:'tools'}">工具</router-link>
        </li>
        <li class="nav-item">
          <router-link :class="route_name== 'other' ? 'nav-link active' : 'nav-link'" :to="{name:'other'}">其他</router-link>
        </li>
        <li class="nav-item">
          <router-link :class="route_name== 'about' ? 'nav-link active' : 'nav-link'" :to="{name:'about'}">关于</router-link>
        </li>
      </ul>
      <ul class="navbar-nav">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            zxasd 
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#">我的空间</a></li>
            <li><a class="dropdown-item" href="#">退出</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>
</template>

<script>
import { useRoute } from 'vue-router';
import { computed } from 'vue';

export default {
    name:"NavBar",
    setup() {
        const route = useRoute();
        let route_name = computed(()=> route.name);
        return {
          route_name,
        }
    },
}
</script>

<style scoped>

</style>